.contenedorCardsCertificados54{
    width: 100%;
    /* margin-left: 5vh; */
    /* border-top: solid 1px black */
}

.card-img-neo{
    height: 25vh;
    object-fit: contain;
    margin-top: 7vh;
}

.card{
    border: none;
}

.card-img-one500{
    height: 25vh;
    object-fit: contain;
    margin-top: 7vh;
}

.card-img-accesorios{
    height: 25vh;
    object-fit: contain;
    margin-top: 7vh;
}

.card-bodyCertificados54{
    display: flex;
    justify-content: center;
}

.tituloCertificados54{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 1200px){
    .card-img-neo{
        height: 18vh;
        margin-top: 0;
    }
    
    .card-img-one500{
        height: 18vh;
        margin-top: 0;
    }

    .card-img-accesorios{
        height: 18vh;
        margin-top: 0;
    }

    .contenedorCardsCertificados{
        /* max-height: 50vh; */
        overflow-y: auto;

    }

}