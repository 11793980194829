.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    
}

.imagenProductoPower3600{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesPower3600{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    margin-top: 10vh;
}

.imgModal{
    width: 100%;
}

.textoProductoPower3600 {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoPower3600 {
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoPower3600 {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoPower3600 {
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavPower3600 {
    margin-top: 9vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosPower3600 {
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

.listadoPower3600 {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

@media (max-width:992px){
    .imagenesPower3600 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-top: 1vh;
    }

    .imagenProductoPower3600 {
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionPower3600 {
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavPower3600 {
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}