.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    
}

.imagenProductoDurtox{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesDurtox{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
}

.imgModal{
    width: 100%;
}

.textoProductoDurtox {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoDurtox {
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoDurtox {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoDurtox {
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavDurtox{
    margin-top: 13vh;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosDurtox {
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
    width: 85%;
     
}

.modal-header h4{
    display: flex;
}

@media (max-width:992px){
    .imagenesDurtox{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-top: 0;
    }

    .imagenProductoDurtox{
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionDurtox{
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavDurtox{
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }

    .linksArchivosDurtox {
        width: 100%;
    }
}