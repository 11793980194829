
footer{
    background-color: #444449;
    text-align: center;
    width: 100%;
}

footer p{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1%;
    color: white;
    font-size: 1em;
}

footer h3{
    font-size: 0.8em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 0;
}

