.contenedorCardsDeteccionGasesTunel {
    width: 100%;
    display: flex;
    justify-content: center;
}

.card-img-sondeltox{
    height: 41vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card-img-durtox{
    height: 33vh;
    object-fit: contain;
    margin-top: 8vh;
    
}

.card-img-durgasTunel{
    height: 38vh;
    object-fit: contain;
    margin-top: 8vh;
    
}

.card{
    border: none !important;
}

.tituloSondeltox{
    margin-top: 5vh;
}

.tituloDurtox{
    margin-top: 9vh;
}

.card-body{
    display: flex;
    justify-content: center;
}

.tituloGasesTunel{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-durgasTunel{
        height: 25vh;
        margin: 0;
                
    }
    
    .card-img-durtox{
        height: 18vh;
        margin: 0;

    }
    
    .card-img-sondeltox{
        height: 25vh;
        margin: 0;

    }

    .contenedorCardsDeteccionGasesTunel{
        overflow-y: auto;

    }

    .tituloDurtox{
        margin-top: 1vh;
    }

    .tituloSondeltox{
        margin-top: 1vh;
    }

    .tituloDurgas{
        margin-top: 1vh;
    }
}