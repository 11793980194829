.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    /* height: auto; */
    
}

.imagenProductoOtrosNoCert{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesOtrosNoCert{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8vh;
}

.imgModal{
    width: 100%;
}

.textoProductoOtrosNoCert {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoOtrosNoCert {
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoOtrosNoCert {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoOtrosNoCert {
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavOtrosNoCert{
    margin-top: 6vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosOtrosNoCert {
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
    width: 95%;
     
}

.modal-header h4{
    display: flex;
}

@media (max-width:992px){
    .imagenesOtrosNoCert{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
    }

    .imagenProductoOtrosNoCert{
        width: auto;
        border: solid grey 1px;
        height: 10vh;
             
    }

    .descripcionOtrosNoCert{
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavOtrosNoCert{
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }

    .linksArchivosOtrosNoCert {
        width: 100%;
    }
}