.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    
}

.imagenProductoColumnas{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesColumnas{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
}

.imgModal{
    width: 100%;
}

.textoProductoColumnas {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoColumnas{
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoColumnas{
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoColumnas{
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavColumnas{
    margin-top: 6vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosColumnas{
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

.modal-header h4{
    display: flex;
    /* text-align: center; */
}

@media (max-width:992px){
    .imagenesColumnas {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
    }

    .imagenProductoColumnas {
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionColumnas {
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavColumnas {
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}