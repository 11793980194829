.contenedorCardsAltavoces {
    width: 100%;
}

.card-img-altCertificados{
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card-img-altNoCertificados{
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card{
    border: none;
}

.tituloAltCertificados {
    margin-top: 2vh;
}

.tituloAltNoCertificados{
    display: flex;
    text-align: left;
    margin-top: 2vh;

}

.card-body{
    display: flex;
    justify-content: center;
}

.tituloAltavoces{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-altCertificados{
        height: 10vh;
                
    }
    
    .card-img-altNoCertificados{
        height: 10vh;

    }

    .contenedorCardsAltavoces{
        overflow-y: auto;

    }
}