.mainContainer {
    display: flex;
}

a {
    color: #fff;
}

.contenedorCardsNoCertificados {
    flex: 1;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    padding: 1%
}

.card-img-techoNoCert {
    height: 25vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-cajas {
    height: 25vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-proyectoresNoCert {
    height: 25vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-columnasNoCert {
    height: 25vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-otrosNoCert {
    height: 25vh;
    object-fit: contain;
    margin-top: 1vh;

}

.tituloNoCertificados{
    display: flex;
    justify-content: center; 
    text-align: center;
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

@media (max-width: 990px) {
    .contenedorCardsNoCertificados .card {
        height: 25vh;
        margin-top: 0;
        width: auto;

    }


    .card-img-techoNoCert {
        height: 16vh;

    }

    .card-img-cajas {
        height: 16vh;

    }

    .card-img-proyectoresNoCert {
        height: 16vh;

    }

    .card-img-columnasNoCert {
        height: 16vh;

    }

    .card-img-otrosNoCert {
        height: 16vh;

    }

    .contenedorCardsDur {
        max-height: 50vh;
    }
    
}

.contenedorCardsNoCertificados {
    overflow-y: auto;
}

