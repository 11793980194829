header {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    margin-right: 2%;
}

.logo {
    float: left;
    display: flex;
    align-items: center;
}

.logo img {
    float: left;
    height: 100px;
    margin-right: 20px;
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    

}

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
    
}

.contenedorHeader{
    width: 100%;
}

.menu{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;
}

.dropdown-item {
    font-size: 1.4rem;
    
}

@media (max-width: 990px) {
    .navbar {
        flex-direction: column;
        align-items: flex-end !important;
        justify-content: flex-end;
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: absolute;
        top: 10.4vh;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff;
        padding: 10px;
        z-index: 9998; 
    }

    .mr-auto {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .nav-link {
        margin-bottom: 10px;
    }

    .navbar-toggler-icon{
        z-index: 99999;
    }

    .dropdown-item{
        font-size: 1.1rem;
    }

    .navbar-collapse{
        font-size: 1.2rem;
    }
}

@media (max-width: 575px){
    .menu{
        margin-top: -8vh;
    }

    .dropdown-item{
        font-size: 0.9rem;
    }

    .navbar-collapse{
        font-size: 1rem;
    }
}

