.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    /* height: auto; */
    
}

.imagenProductoDurtex{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesDurtex{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
}

.imgModal{
    width: 100%;
}

.textoProductoDurtex {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoDurtex {
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoDurtex {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoDurtex {
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavDurtex{
    margin-top: 6vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosDurtex {
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
    width: 95%;
     
}

.modal-header h4{
    display: flex;
}

@media (max-width:992px){
    .imagenesDurtex{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
    }

    .imagenProductoDurtex{
        width: auto;
        border: solid grey 1px;
        height: 10vh;
             
    }

    .descripcionDurtex{
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavDurtex{
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }

    .linksArchivosDurtex {
        width: 100%;
    }
}