.mainContainer {
    display: flex;
    height: 100vh;
    /* Establece el 100% de la altura de la ventana */
}

a {
    color: #fff;
}

/* .sc-JrDLc{
    width: auto;
} */

.contenedorCardsTuneles {
    flex: 1;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    /* Agrega scroll si el contenido es más grande que el contenedor */
    /* padding: 2%;  */
    /* margin: 1%; */
    /* width: 100%; */
    padding: 1%
}

.card-img-gasesTunel {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-opacimetro {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-luminosos {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-anemometros {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;
    margin-left: 10%;

}

.card-img-atmosfericos {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.tituloTunel{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* .card-body{
    display: flex;
    justify-content: center;
} */


/* .contenedorMenu{
    width: auto;
    height: auto;
} */

@media (max-width: 990px) {
    .contenedorCardsTuneles .card {
        height: 20vh;
        margin-top: 10%;
        width: auto;
    }


    .card-img-gasesTunel {
        height: 15vh;
    }

    .card-img-opacimetro {
        height: 15vh;

    }

    .card-img-luminosos {
        height: 15vh;

    }

    .card-img-anemometros {
        height: 15vh;
        margin-left: 0;

    }

    .card-img-atmosfericos {
        height: 15vh;

    }

    .contenedorCardsDur {
        max-height: 90vh;
    }
}

.contenedorCardsTuneles {
    /* max-height: 50vh; */
    overflow-y: auto;

}

