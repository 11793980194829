.contenedorCardsAccesoriosNeo {
    width: 100%;
}

.card-img-power3600{
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card-img-power960{
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card{
    border: none;
}

.tituloPower3600 {
    margin-top: 2vh;
}

.tituloPower960{
    display: flex;
    text-align: left;
    margin-top: 2vh;

}

.card-body{
    display: flex;
    justify-content: center;
}

.tituloAccesoriosNeo{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-power3600{
        height: 10vh;
                
    }
    
    .card-img-power960{
        height: 10vh;

    }

    .contenedorCardsAccesoriosNeo{
        overflow-y: auto;

    }
}