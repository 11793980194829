.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    
}

.imagenProductoDirex{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesDirex{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
}

.imgModal{
    width: 100%;
}

.textoProductoDirex {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoDirex{
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoDirex{
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoDirex{
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavDirex{
    margin-top: 13vh;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
    }

.linksArchivosDirex{
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

.modal-header h4{
    display: flex;
    /* text-align: center; */
}

@media (max-width:992px){
    .imagenesDirex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
    }

    .imagenProductoDirex {
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionDirex {
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavDirex {
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}