.container {
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-gap: 50px;
    margin-top: 3rem!important;
    min-height: 100vh;

}

.contenedorGeneral{
    display: flex;
    height: auto !important;
}

.testPruebas {
    border-top: solid 1.5px #dbdbdb;
    /* padding-left: 8%; */
    /* padding-right: 8%; */
    min-height: 100vh;
}

.nombreTestPruebas {
    display: flex;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
    color: #0D627A;
    font-size: 2.7rem;
}

.textoTestPruebas {
    text-align: justify;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 2%;
}

@media (max-width: 768px) {
    .container {
        margin-top: 18vh;
    }

    .historia {
        margin-top: 18vh;
    }

    .textoTestPruebas{
        font-size: 1rem;
        text-align: left;
    }

    .nombreTestPruebas{
        font-size: 2rem;
    }
}