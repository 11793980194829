.container {
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-gap: 50px;
    margin-top: 3rem!important;
    min-height: 100vh;

}

.contenedorGeneral{
    display: flex;
    height: auto !important;
}

.mantenimientos {
    border-top: solid 1.5px #dbdbdb;
    min-height: 100vh;
}

.nombreMantenimientos {
    display: flex;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
    color: #0D627A;
    font-size: 2.7rem;
}

.textoMantenimientos {
    text-align: justify;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 2%;
}

@media (max-width: 768px) {
    .container {
        margin-top: 18vh;
    }

    .historia {
        margin-top: 18vh;
    }

    .mantenimientos{
        overflow-y: auto;
    }

    .textoMantenimientos{
        font-size: 1rem;
        text-align: left;
    }

    .nombreMantenimientos{
        font-size: 2rem;
    }
}