.menuLateral {
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 21%;
    height: auto;
    background: linear-gradient(rgb(25, 128, 156) 0%,rgba(0,64,82,1) 100%);
    border-right: 1px solid #ddd;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

.botonMenu {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    cursor: pointer;
}

.botonMenu i {
    font-size: 20px;
}

.menuLateralItems {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menuLateralItems li {
    padding: 10px;
    border-top: 1px solid gray;
}

.menuLateralItems li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    padding: 5px;
    border-radius: 10px;
}

.menuLateralItems li a.activo {
    background-color: #B6D9E0;
    color: #0D627A;
}

.menuLateralItems li a:hover {
    background-color: #B6D9E0;
    color: #0D627A;
}

.menuLateralState {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ddd;
    color: #333;
}

.menuLateral.abierto {
    width: auto;
}

.menuLateral.abierto .botonMenu {
    display: none; /* Ocultar el botón cuando el menú está abierto */
}

.menuLateral.cerrado {
    width: 50px;
}

@media (max-width: 768px){
    .menuLateral{
        display: none;
    }
}