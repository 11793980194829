.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    /* overflow-y: auto;     */

}

.imagenProductoDurpark{
    width: auto;
    border: solid grey 1px;
    margin: 5vh;
    height: 20vh;

         
}

.imagenesDurpark{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
}

.imgModal{
    width: 100%;
}

.textoProductoDurpark {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoDurpark{
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}


.nombreProductoDurpark{
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavDurpark{
    margin-top: 9vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosDurpark{
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

@media (max-width:992px){
    .imagenesDurpark{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-top: 0;
    }

    .imagenProductoDurpark{
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionDurpark{
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavDurpark{
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}