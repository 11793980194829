.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
    
}

.imagenProductoStandgasHcPro{
    width: 10vw;
    border: solid grey 1px;
    margin: 5vh;
         
}

.imagenesStandgasHcPro{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    margin-top: 20vh;
}

.imgModal{
    width: 100%;
}

.textoProductoStandgasHcPro {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoStandgasHcPro {
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoStandgasHcPro {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoStandgasHcPro {
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavStandgasHcPro {
    margin-top: 9vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosStandgasHcPro {
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

@media (max-width:992px){
    .imagenesStandgasHcPro {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-top: 1vh;
    }

    .imagenProductoStandgasHcPro {
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionStandgasHcPro {
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavStandgasHcPro {
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}