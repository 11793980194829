#nombreEmpresa{
    background: linear-gradient(rgb(25, 128, 156) 0%,rgba(0,64,82,1) 100%);
    width: 100%;
    padding: 1% 1%;
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 5px;

}

/* Datos de contacto */

.container-form {
    width: 100%;
    margin: auto;    
    min-height: 100vh;
    border-top: solid 1.5px #dbdbdb;
}

.container-form h1{
    width: 100%;
    margin-bottom: 1.5%;
    font-size: 35px;
}

.container-form h2{
    margin-bottom: 15px;
    font-size: 35px;
}

.container-form .info-form .datos{
    display: flex;
    flex-direction: column;
}

/* Texto redes */

.container-form a{
    font-size: 17px;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    margin-bottom: 15px;
    color: black;
    font-weight: 700;
}

/* Link redes */

.container-form .link-redes{
    color: #00BCD4;
}

/* Formulario */

.form-label{
    
    padding-left: 2rem;
}

.form-control{
    margin-left: 2rem;
    max-width: 90%;
}

.form-select {
    margin-left: 2rem;
    max-width: 90%;
    margin-top: 3%;
    margin-bottom: 2%;
}

/* Boton enviar */

#boton{
    font-size: 16px;
    border: none;
    outline: 0px;
    background: rgb(25, 128, 156);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 300mx ease;
}

#boton:hover{
    background-color: rgba(0,64,82,1);
}

.btn{
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.container-form .info-form .datos{
    border: solid 1.5px #D2D2D2;
    margin-bottom: 1.5%;
}

/* MOBILE */

@media (max-width: 768px) {
    .card{
        margin-top: 10vh;
    }
} 

@media (max-width: 1100px) {
    .card {
        margin-top: 10vh;
    }

    .container-form {
        margin-top: 10vh;
    }
}