.mainContainer {
    display: flex;
    height: 100vh;    
}

a{
    color: #fff;
}

.contenedor{
    width: calc(100% - 21%);
    overflow-y: auto; 
}

.tituloGases{
    display: flex;
    justify-content: center; 
    text-align: center;
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

.contenedorCardsDeteccionGases {
    display: flex;
    justify-content: space-between;
    overflow-y: auto; 
    padding: 20px; 
    width: 100%;
    box-sizing: border-box;
}

.card-img-toxicos{
    border-radius: 10px;
    margin: 1%;
    object-fit: contain;
}

.card-img-explosivos{
    border-radius: 10px;
    margin: 1%;
    object-fit: contain;
}

@media (max-width: 992px){
    .contenedorCardsDeteccionGases {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

}

@media (max-width: 710px){
    .contenedor{
        width: 100%;
    }
}