.contenedorCardsInicio {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.contenedorCardsInicio .row {
    width: 100%;
    margin: 0 1%;
}

.contenedorCardsInicio .card {
    margin: 1%;
    border: none;
    width: 100%;
    padding: 2%;
}

.card-img-top {
    width: 100%;
}





