.contenedorCardsDur{
    width: 100%;
    
}

.card-img-durgas{
    height: 41vh;
    object-fit: contain;
    margin-top: 4vh;
    
}

.card{
    border: none;
}

.card-img-durpark{
    height: 33vh;
    object-fit: contain;
    margin-top: 7vh;
}

.tituloDurpark{
    margin-top: 5vh;
}

.tituloDurgas{
    display: flex;
    text-align: left;
}

.card-body{
    display: flex;
    justify-content: center;
}

.tituloSistCentralizados{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-durgas{
        height: 25vh;
                
    }
    
    .card-img-durpark{
        height: 18vh;

    }

    .contenedorCardsDur{
        max-height: 50vh;
    }
}