.contenedorCardsAutonomosE {
    width: 100%;
}

.card-img-standgasHc{
    height: 41vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card-img-standgasHcPro{
    height: 41vh;
    object-fit: contain;
    margin-top: 5vh;
    
}

.card-img-standgasProLcdExp{
    height: 41vh;
    object-fit: contain;
    margin-top: 8vh;
    
}

.card{
    border: none;
}

.tituloStandgasHc {
    margin-top: 2vh;
}

.tituloStandgasHcPro {
    margin-top: 2vh;
}

.tituloStandgasProLcdExp{
    display: flex;
    text-align: left;
}

.card-body{
    display: flex;
    justify-content: center;
}

.tituloDetAutonomosE{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-standgasHc{
        height: 18vh;
                
    }

    .card-img-standgasHcPro{
        height: 18vh;
                
    }
    
    .card-img-standgasProLcdExp{
        height: 18vh;

    }

    .contenedorCardsAutonomosE{
        overflow-y: auto;

    }
}