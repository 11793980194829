.contenedorProducto{
    width: auto;
    border-top: #177C97 solid 2px;
}

.imagenProductoCable{
    width: auto;
    border: solid grey 1px;
    margin: 5vh;
    height: 20vh;
         
}

.imagenesCable{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6vh;
}

.imgModal{
    width: 100%;
}

.textoProductoCable {
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.tituloProductoCable{
    text-align: justify;
    margin-top: 5vh;
    font-size: 1.2rem;
    color: #0D627A;
}

.listadoCable{
    text-align: justify;
    margin-top: 2vh;
    font-size: 1.2rem;
}

.nombreProductoCable{
    text-align: center;
    margin-top: 4vh;
    color: #0D627A;
}

.contenedorNavCable{
    margin-top: 9vw;
    display: flex;
    justify-content: center;
    border-radius: 1000px;
}

.linksArchivosCable{
    padding: 1vw;
    color: #0D627A;
    cursor: pointer;
    background-color: #B6D9E0;
    margin: 0.3vw;
     
}

.modal-header h4{
    display: flex;
    /* text-align: center; */
}

@media (max-width:1400px){
    .imagenProductoCable{
        height: 12vh;
    }

}

@media (max-width:992px){
    .imagenesCable{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
    }

    .imagenProductoCable{
        width: auto;
        border: solid grey 1px;
        margin: 5vh;
        height: 15vh;
             
    }

    .descripcionCable{
        margin: 5%;
        height: auto;
        font-size: 0.5;
    }

    .contenedorNavCable{
        margin-top: 1vw;
        margin-bottom: 5%;
    }

    .contenedorProducto{
        overflow-y: auto;
    }
}