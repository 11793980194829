.contenedorCards4_20E {
    width: 100%;
    display: flex;
    justify-content: center;
}

.card-img-durtex{
    height: 50vh;
    object-fit: contain;
    margin-top: 1vh;
    
}

.card{
    border: none;
}


.card-body{
    display: flex;
    justify-content: center;
}

.tituloDet4_20maE{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 990px){
    .card-img-durtex{
        height: 25vh;
                
    }
    
    .contenedorCards4_20E {
        overflow-y: auto;

    }
}