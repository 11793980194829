.contenedorCardsAccesoriosNeoYOne{
    width: 100%;
    /* margin-left: 5vh; */
}

.card-img-MPS{
    height: 25vh;
    object-fit: contain;
    margin-top: 7vh;
}

.card{
    border: none;
}

.card-img-selector{
    height: 25vh;
    object-fit: contain;
    margin-top: 7vh;
}

.card-img-alarma{
    height: 18vh;
    object-fit: contain;
    margin-top: 7vh;
    margin-bottom: 7vh;
}

.card-img-otros{
    height: 25vh;
    object-fit: contain;
    margin-top: 5vh;
}

.card-bodyAccesoriosNeoYOne{
    display: flex;
    justify-content: center;
}

.tituloAccesoriosNeoYOne{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* MOBILE*/

@media (max-width: 1200px){
    .card-img-MPS{
        height: 18vh;
        margin-top: 0;
                
    }
    
    .card-img-selector{
        height: 18vh;
        margin-top: 0;
    }

    .card-img-alarma{
        height: 18vh;
        margin-top: 0;
    }
    
    .card-img-otros{
        height: 18vh;
        margin-top: 0;
    }

    .contenedorCardsAccesoriosNeoYOne{
        /* max-height: 50vh; */
        overflow-y: auto;

    }

}