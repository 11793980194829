.mainContainer {
    display: flex;
    height: 100vh;
    /* Establece el 100% de la altura de la ventana */
}

a {
    color: #fff;
}

/* .sc-JrDLc{
    width: auto;
} */

.contenedorCardsSensoresAtmosfericos {
    flex: 1;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    /* Agrega scroll si el contenido es más grande que el contenedor */
    /* padding: 2%;  */
    /* margin: 1%; */
    /* width: 100%; */
    padding: 1%;
    margin-right: 2%;
}

.card-img-visibilidad {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;
}

.card-img-pluviometro {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-humedad {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-barometro {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-piranometro {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.card-img-hielo {
    height: 41vh;
    object-fit: contain;
    margin-top: 1vh;

}

.tituloAtmosfericos{
    display: flex;
    justify-content: center; 
    text-align: center;
    /* margin-top: 4vh; */
    color: #0D627A; 
    border-bottom: #177C97 solid 2px;  
    border-top: #177C97 solid 2px;
}

/* .card-body{
    display: flex;
    justify-content: center;
} */


/* .contenedorMenu{
    width: auto;
    height: auto;
} */

@media (max-width: 990px) {
    .contenedorCardsSensoresAtmosfericos .card {
        height: 12vh;
        /* margin-top: 0; */
        width: auto;

    }


    .card-img-visibilidad {
        height: 11vh;

    }

    .card-img-pluviometro {
        height: 11vh;

    }

    .card-img-humedad {
        height: 11vh;

    }

    .card-img-barometro {
        height: 11vh;

    }

    .card-img-piranometro {
        height: 11vh;

    }

    .card-img-hielo {
        height: 11vh;

    }

    .tituloSensoresAtmosfericos  {
        font-size: 1rem !important;
    }

    .contenedorCardsSensoresAtmosfericos {
        /* max-height: 50vh; */
        overflow-y: auto;

    }

}