.container {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-gap: 50px;
    margin-top: 3rem!important;
    min-height: 100vh;

}

.contenedorGeneral{
    display: flex;
    height: auto !important;
}

.empresa {
    border-top: solid 1.5px #dbdbdb;
    padding-left: 8%;
    padding-right: 8%;
    min-height: 100vh;
}

.nombreEmpresa {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    color: #0D627A;
    font-size: 2.7rem;
}

.subNombreEmpresa {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    color: #0D627A;
}

.quien{
    font-size: 1.7rem; 
    margin-bottom: 3%;
    margin-top: 2%;
}

.textoEmpresa {
    text-align: justify;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 2%;
}

@media (max-width: 768px) {
    .container {
        margin-top: 18vh;
    }

    .historia {
        margin-top: 18vh;
    }

    .textoEmpresa{
        font-size: 1rem;
        text-align: left;
    }

    .nombreEmpresa{
        font-size: 2rem;
    }

    .subNombreEmpresa{
        font-size: 1.5rem;
        text-align: center;
    }

    .quien{
        font-size: 1.4rem;
    }
}